import * as THREE from "three";
import initializeDomEvents from "threex-domevents";
import Stats from "three/examples/jsm/libs/stats.module";
import controls from "./controls";

export default function initEditor(scene = false) {
  scene = scene ? scene : new THREE.Scene();
  const THREEx = {};
  initializeDomEvents(THREE, THREEx);
  const camera = new THREE.PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.1,
    2000
  );
  camera.position.z = 1;
  camera.position.y = 1;

  const renderer = new THREE.WebGLRenderer({
    preserveDrawingBuffer: true
  });
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.querySelector("#editor-can").appendChild(renderer.domElement);

  const controlers = controls(scene, camera, renderer);
  const domEvent = new THREEx.DomEvents(camera, renderer.domElement);

  const spotLight = new THREE.HemisphereLight(0xffffff);
  spotLight.position.set(0, 20, 10);
  spotLight.userData = { type: "HemisphereLight" };
  scene.add(spotLight);

  // ground
  console.log(scene.children)
  scene.children.map((child, i) => {
    if (!child.userData.internalOnly) {
      console.log(child)
      domEvent.addEventListener(
        child,
        "click",
        (e) => {
          controlers.transformControls.attachEle(e.target);
        },
        false
      );
    }
  });

  const grid = new THREE.GridHelper(
    2.5,
    50,
    new THREE.Color("#99d3ff"),
    new THREE.Color("#99d3ff")
  );
  grid.material.opacity = 0.5;
  grid.material.transparent = true;
  grid.userData = { internalOnly: true };
  scene.add(grid);

  window.addEventListener("resize", onWindowResize, false);
  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    render();
  }

  function render() {
    renderer.render(scene, camera);
  }

  return {
    scene: scene,
    renderer: renderer,
    camera: camera,
    render: render,
    controls: controlers,
    domEvent: domEvent,
  };
}
