import * as THREE from "three";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/addons/loaders/FBXLoader.js'


const loadScene = (editor, scene)=>{

}
const addObj = (editor, obj) => {
  editor.domEvent.addEventListener(
    obj,
    "click",
    (e) => {
      editor.controls.transformControls.attachEle(e.target)
    },
    false
  );
  editor.controls.transformControls.attach(obj);
  editor.scene.add(obj);
};

const LoadObject = {
  fromUrl: async (editor, url = null) => {
    const loader = new THREE.ObjectLoader();

    let obj = await loader.loadAsync(url);
    addObj(editor, obj);
  },
  fromObject: (editor, object) => {
    const loader = new THREE.ObjectLoader();
    var obj = loader.parse(object);
    addObj(editor, obj);
  },
};


const LoadFbx = {
  fromObject: async (editor, file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        LoadFbx.fromUrl(editor, reader.result);
      },
      false
    );
    reader.readAsDataURL(file);
  },
  fromUrl: (editor, url = null) => {
    const loader = new FBXLoader();
    loader.load( url, function ( obj ){
          
          addObj(editor, obj);
      } );
    
  },
};

const LoadGltf = {
  fromObject: async (editor, file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        LoadGltf.fromUrl(editor, reader.result);
      },
      false
    );
    reader.readAsDataURL(file);
  },
  fromUrl: (editor, url = null) => {
    const loader = new GLTFLoader();
    loader.load( url, function ( gltf ){
          var obj = gltf.scene;
          addObj(editor, obj);
      } );
    
  },
};

export const LoadImage = {
  fromUrl: (editor, url) => {
    var loader = new THREE.TextureLoader();
    var material = new THREE.MeshLambertMaterial({
      map: loader.load(url),
    });
    var geometry = new THREE.PlaneGeometry(10, 10 * 0.75);
    var mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0, 0);
    mesh.userData = { type: "Image" };
    addObj(editor, mesh);
  },
  fromFile: (editor, file) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        LoadImage.fromUrl(editor, reader.result);
      },
      false
    );
    reader.readAsDataURL(file);
  },
};

export default LoadObject;
export {addObj, LoadGltf, LoadFbx};