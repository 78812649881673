import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import * as THREE from "three";
import initEditor from "./utils/editor";
import "./App.css";
import App from "./App";
import { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
} from "react-router-dom";
import Api from "./lib/api";

const Comp = () => {
  const [editor, setEditor] = useState({});
  const { uuid } = useParams();
  useState(async () => {
    const project = await Api.get(`project/${uuid}`).then((res)=>res.data)
    const json = await axios.get(project.file).then((res) => res.data);
    const loader = new THREE.ObjectLoader();

    const initEdit = initEditor(loader.parse(json));
    const animate = () => {
      requestAnimationFrame(animate);
      initEdit.render();
    };
    animate();
    setEditor(initEdit);
  }, [null]);

  if (editor.scene) {
    return (
      <>
        <App editor={editor} setRootEditor={setEditor} />
      </>
    );
  }

  return <></>;
};
const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/:uuid",
    element: (
      <>
        <Comp />
      </>
    ),
  },
]);
root.render(
  <>
    <RouterProvider router={router} />
  </>
);
