import { combineReducers } from "redux";


const initialState = {};

const editorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_EDITOR":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
const themeReducer = (state = {
  bigMenuIsOpen:false
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_THEME":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const reducers = combineReducers({
  editor: editorReducer,
  theme: themeReducer
});



export function mapStateToProps(state) {
  return {
    state:state,
    editor:state.editor,
    theme:state.theme
  }
}


export function  mapDispatchToProps  (dispatch){
  return {
    setEditor:(data = { }) => {
      dispatch({
        type: "UPDATE_EDITOR",
        payload: data,
      })
    },
    setTheme:(data = { }) => {
      dispatch({
        type: "UPDATE_THEME",
        payload: data,
      })
    }
  }
}

export default reducers;
