import { TransformControls  , TransformControlsGizmo } from "three/examples/jsm/controls/TransformControls";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default function controls(scene , camera , renderer) {

  // var gizmo =  new TransformControlsGizmo()
  
  // console.log( JSON.stringify(gizmo.gizmo.translate.toJSON()) )
  var transformControls = new TransformControls(camera, renderer.domElement)
  var orbitControls = new OrbitControls(camera, renderer.domElement);
  
  transformControls.addEventListener('dragging-changed', function (event) {
    orbitControls.enabled = !event.value
    //dragControls.enabled = !event.value
  })
  
  transformControls.userData =  {internalOnly:true, type:"TransformControls"}
  scene.add(transformControls)
  transformControls.attachEle = (target) =>{
    if(target.parent === null){
      console.log(null)
    return 
    }
    transformControls.attach(target)
  }
  return {
    transformControls: transformControls,
    orbitControls:orbitControls
  };
}
