import { FiMenu } from "react-icons/fi";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Api from "../../lib/api";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../utils/redux/reducers";

const Navbar = ({ theme, setTheme, editor, setEditor, ...props }) => {
  const { uuid } = useParams();
  return (
    <>
      <div className="h-14 z-[1000] w-full bg-[#000] shrink-0">
        <div className="flex justify-between items-center h-full w-full">
          <div className="flex items-center pl-2">
            <a
              className="text-gray-300 mx-3 hidden md:block"
              href="#"
              onClick={() => {
                theme.bigMenuIsOpen
                  ? setTheme({ ...theme, bigMenuIsOpen: false })
                  : setTheme({ ...theme, bigMenuIsOpen: true });
              }}
            >
              <FiMenu className="h-8 w-8" />
            </a>
          </div>
          <div className="px-2">\
          {/* <a
            className="p-2 shadow rounded-sm bg-gray-700 text-white"
              href="#">
                Settings
              </a>
              <a
            className="p-2 shadow rounded-sm bg-gray-700 text-white"
              href="#">
                Preview
              </a>
              <a
            className="p-2 shadow rounded-sm bg-gray-700 text-white"
              href="#">
                Publish
              </a> */}
            <a
            className="p-2 mr-2 shadow rounded-sm bg-gray-700 text-white"
              href={`https://v1.webarpro.com/p/${uuid}`}>
                Open 
              </a>
            <a
            className="p-2 shadow rounded-sm bg-gray-700 text-white"
              href="#"
              onClick={() => {
                editor.controls.transformControls.detach();

                const data = new FormData();
                // console.log(editor.renderer.domElement.toBlob( 'image/png' ))
                data.append(
                  "file",
                  new Blob([JSON.stringify(editor.scene.toJSON())], {
                    type: "application/json",
                  }),
                  "file.json"
                );
                editor.renderer.domElement.toBlob((d) => {
                  data.append(
                    "thumbnail",
                    d,
                    "file.png"
                  );
                  Api.patch(`project/${uuid}`, data);
                }, "image/png");
              }}
            >
              Save
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
