import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../../utils/redux/reducers";
import ObjectEditor from "./editor/ObjectEditor";

const ElementsPanel_ = ({ setEditor, ...props }) => {
  const [elements, setElements] = useState(props.state.editor.scene.children);
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div
      style={{ height: "calc(100vh - 8em)" }}
      className={
        "fixed z-[99999]  transition-all	 delay-75  rounded-sm right-0 bottom-0 bg-[#111] w-96 max-w-100vw " +
        (isOpen ? " " : "translate-x-[100%]")
      }
    >
      <div className="relative px-4 w-full h-full">
        <button
          onClick={() => {
            isOpen ? setIsOpen(false) : setIsOpen(true);
          }}
          className="bg-[#111] mt-2 bor der rounded-l-full text-white absolute top-0 w-10 h-10 -ml-8 left-0"
        >
          {isOpen ? (
            <FiChevronsRight className="h-8 p-2 w-8" />
          ) : (
            <FiChevronsLeft className="h-8 p-2 w-8" />
          )}
        </button>
        <div className="w-full h-1/2 pb-2 flex flex-col">
          <div className="p-2 ">
            <h2 className="text-white text-lg font-bold">Elements</h2>
          </div>
          <div className="p-2 bg-[#222] grow rounded overflow-y-auto">
            {elements.map((child, i) => {
              if (child.userData.internalOnly != true) {
                return (
                  <div
                    key={i}
                    className="relative flex justify-between items-center rounded-sm my-2  text-white  border w-full hover:shadow transition duration-75 "
                  >
                    <span
                      className="text-sm w-full h-full px-2 py-3"
                      onClick={() => {
                        props.state.editor.controls.transformControls.attach(
                          child
                        );
                      }}
                    >
                      {child.name ? child.name: (child.userData.type ? child.userData.type : " Object ")}
                    </span>
                    <button
                      onClick={() => {
                        props.state.editor.controls.transformControls.detach();
                        props.state.editor.scene.remove(child);

                        setEditor({...props.state.editor,controls: props.state.editor.controls});
                      }}
                      className="border absolute top-0 right-0 m-1  rounded-full p-2"
                    >
                      <MdDelete className="text-white h-4 w-4" />
                    </button>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <hr />
        <div className="w-full h-1/2 flex pb-2 flex-col">
          <Tab.Group>
            <Tab.List className={"px-2 pt-2 border-b  border-gray-600"}>
              <Tab className="text-white text-sm p-2 border rounded-t border-gray-600 focus:outline-0">
                Object
              </Tab>
              {/* <Tab className="text-white text-sm p-2 border rounded-t border-gray-600 focus:outline-0">
                Geometry
              </Tab>
              <Tab className="text-white text-sm p-2 border rounded-t border-gray-600 focus:outline-0">
                Material
              </Tab> */}
            </Tab.List>
            <Tab.Panels className={"grow w-full rounded mt-2 bg-[#222] overflow-y-auto"}>
              <Tab.Panel className={"w-full h-full "}>
                <ObjectEditor
                  editor={props.state.editor}
                  setEditor={setEditor}
                />
              </Tab.Panel>
              <Tab.Panel className={"w-full h-full"}>Content 2</Tab.Panel>
              <Tab.Panel className={"w-full h-full"}>Content 3</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          {/* <div className="p-2">
            <h2 className="text-white text-lg font-bold">Settings</h2>
          </div>
          <div className="p-2 bg-[#222] grow rounded"></div> */}
        </div>
      </div>
    </div>
  );
};
const ElementsPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(ElementsPanel_);
export default ElementsPanel;
