import Navbar from "../navbar";
import Sidebar from "../sidebar";
import { useStore } from "../../utils/redux/store";

import {
  MdOutlineCropRotate,
  MdOutlineRemoveCircleOutline,
} from "react-icons/md";
import { GiResize, GiMove } from "react-icons/gi";

import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../utils/redux/reducers";
import { FiChevronsRight } from "react-icons/fi";
import ElementsPanel from "../elements";

function Editor({ ...props }) {
  return (
    <div className="h-screen w-screen ">
      <div className=" flex flex-col  h-full">
        <Navbar />
        <div className="grow flex overflow-hidden ">
          <Sidebar />

          <div id="editor" className="grow  shrink-0 overflow-hidden h-full  ">
            <div className="fixed z-[100000] right-0 top-0 bg-[#111] flex items-center mt-16 mx-2 h-12  px-4">
              <div className=" justifu-between items-center bg-[#222] h-10 flex h-full">
                <a
                  onClick={() =>
                    props.editor.controls.transformControls.setMode("rotate")
                  }
                  className="text-gray-300 text-lg h-10 rounded-sm w-10 flex text-lg justify-center hover:bg-[#333]  items-center rounded-sm "
                >
                  <MdOutlineCropRotate />
                </a>
                <a
                  onClick={() =>
                    props.editor.controls.transformControls.setMode("scale")
                  }
                  className="text-gray-300 text-lg h-10 rounded-sm w-10 flex text-lg justify-center hover:bg-[#333] items-center rounded-sm "
                >
                  <GiResize />
                </a>
                <a
                  className="text-gray-300 text-lg h-10 w-10 rounded-sm flex text-lg justify-center hover:bg-[#333]  items-center rounded-sm "
                  onClick={() =>
                    props.editor.controls.transformControls.setMode("translate")
                  }
                >
                  <GiMove />
                </a>
                {/* <a className="text-gray-300 text-lg h-10 w-10 rounded-sm flex text-lg justify-center hover:bg-[#333]  items-center rounded-sm "
                  onClick={()=>props.editor.controls.transformControls.clear()} >
                    <MdOutlineRemoveCircleOutline
                    
                    />
                    
                  </a> */}
              </div>
            </div>
           <ElementsPanel />
          </div>
        </div>
      </div>
      {/* </Provider> */}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
