import { connect } from "react-redux";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../utils/redux/reducers";
import { Tab } from "@headlessui/react";
import { Fragment, useState } from "react";

import { FaShapes } from "react-icons/fa";
import { RiUploadCloudFill } from "react-icons/ri";
import { IoMdArrowBack } from "react-icons/io";

import { IoMdCube, IoMdImage } from "react-icons/io";
import LoadObject, {
  LoadImage, LoadGltf, LoadFbx
} from "../../../utils/editor/tools/objects/loader";
const AddObjectComp = ({ editor, setEditor, theme, setTheme, ...props }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div className="p-2  h-full flex flex-col">
      <div className="w-full sticky pt-3 md:pt-auto top-0">
        <h3 className=" px-3 md:px-2 text-gray-100 text-xl">Add To Scene</h3>
      </div>
      <Tab.Group
        as={"div"}
        selectedIndex={selectedTab}
        className="relative grow w-full"
      >
        <Tab.List className={"grid grid-cols-2 gap-2 pt-6"}>
          <Tab className={"border p-2 hidden"}></Tab>
          <Tab
            onClick={() => setSelectedTab(1)}
            className={" p-2 shadow rounded h-36 bg-[#222]"}
          >
            <div className="h-full w-full relative flex justify-center items-center">
              <FaShapes className="h-16 text-gray-100 w-16" />
              <span className="absolute bottom-0 text-white">
                {" "}
                Add Elements
              </span>
            </div>
          </Tab>
          <Tab
            onClick={() => setSelectedTab(2)}
            className={" p-2 shadow rounded h-36 bg-[#222]"}
          >
            <div className="h-full w-full relative flex justify-center items-center">
              <IoMdImage className="h-16 text-gray-100 w-16" />
              <span className="absolute bottom-0 text-white"> Add Image</span>
            </div>
          </Tab>
        </Tab.List>
        <Tab.Panels as="div">
          <Tab.Panel></Tab.Panel>
          <Tab.Panel
            className={"absolute top-0 left-0 w-full h-full bg-[#151515]"}
          >
            <>
              <div className="h-12  rounded mt-3 px-3 flex items-center justify-between">
                <div className="flex items-center">
                  <a
                    className="rounded-full p-1 border"
                    onClick={() => setSelectedTab(0)}
                  >
                    <IoMdArrowBack className="text-white h-6 w-6" />
                  </a>
                </div>
                <h4 className="text-lg text-gray-100">Add Elements</h4>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-2">
                <div className="h-12 bg-[#222] rounded flex items-center relative justify-center">
                  GLTF
                  <input
                    type={"file"}
                    onChange={(e) => {
                      LoadGltf.fromObject(editor, e.target.files[0]);
                      setEditor(editor);
                    }}
                  />
                </div>
                <div className="h-12 bg-[#222] rounded flex items-center relative justify-center">
                  FBX
                  <input
                    type={"file"}
                    onChange={(e) => {
                      LoadFbx.fromObject(editor, e.target.files[0]);
                      setEditor(editor);
                    }}
                  />
                </div>
                <div
                  onClick={async () => {
                    await LoadObject.fromUrl(
                      editor,
                      "/objects/shapes/cube.json"
                    );
                    setEditor(editor);
                  }}
                  className="h-40 bg-[#222] rounded flex items-center justify-center"
                >
                  <IoMdCube className="h-14 text-white w-14" />
                </div>
              </div>
            </>
          </Tab.Panel>

          <Tab.Panel
            className={"absolute top-0 left-0 w-full h-full bg-[#151515]"}
          >
            <>
              <div className="h-12  rounded mt-3 px-3 flex items-center justify-between">
                <div className="flex items-center">
                  <a
                    className="rounded-full p-1 border"
                    onClick={() => setSelectedTab(0)}
                  >
                    <IoMdArrowBack className="text-white h-6 w-6" />
                  </a>
                </div>
                <h4 className="text-lg text-gray-100">Add Image</h4>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-2">
                <div className="h-12 bg-[#222] rounded flex items-center relative justify-center">
                  <input
                    type={"file"}
                    onChange={(e) => {
                      LoadImage.fromFile(editor, e.target.files[0]);
                      setEditor(editor);
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    LoadImage.fromUrl(editor, "/logo192.png");
                    setEditor(editor);
                  }}
                  className="h-40 bg-[#222] rounded flex items-center relative justify-center"
                >
                  <img
                    src="/logo192.png"
                    className="object-cover layout-fill"
                  />
                </div>
              </div>
            </>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddObjectComp);
