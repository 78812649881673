import { Tab } from "@headlessui/react";
import { Fragment, useState } from "react";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import {FiMenu} from "react-icons/fi"
import {HiViewGridAdd} from "react-icons/hi"
import { connect } from "react-redux";
import { mapStateToProps , mapDispatchToProps } from "../../utils/redux/reducers";

import AddObjectComp from "./AddObjects"

const Sidebar = ({theme, setTheme, editor, setEditor,  ...props }) => {
  
  return (
    <>
      <div className="w-0 h-full bg-gray-800/90 shrink-0">
        <div className="fixed w-full bottom-0 flex rounded-t-3xl sm:rounded-none flex-col-reverse	 sm:w-auto sm:top-0 sm:left-0 sm:flex sm:flex-row sm:h-screen bg-[#151515] z-[50]">
        
          <Tab.Group as={Fragment}>
            <Tab.List as={"div"} className="w-screen flex-row  sm:w-14 sm:pt-16  bg-[#111] flex sm:flex-col items-center h-full">
              <div onClick={
                ()=>{ theme.bigMenuIsOpen ? setTheme({...theme, bigMenuIsOpen:false }) : setTheme({...theme, bigMenuIsOpen:true }) }
              } className={"h-10 m-2 border-r pr-2 md:hidden flex items-center"}>
                  <FiMenu  className="text-gray-200 h-8 w-8" />
              </div>
            
              <Tab onClick={
                ()=>{ setTheme({...theme, bigMenuIsOpen:true }) }
              } className={"h-10 m-2"}>
                <HiViewGridAdd  className="text-gray-200 h-8 w-8" />
              </Tab>
              {/* <Tab className={"h-10 m-2"}>
                <MdOutlineDashboardCustomize  className="text-gray-200 h-8 w-8" />
              </Tab>
              <Tab className={"h-10 m-2"}>
                <FaCubes  className="text-gray-200 h-8 w-8" />
              </Tab> */}
              
            </Tab.List>
            <Tab.Panels className={`w-full  h-[75vh] sm:w-80 sm:pt-16  sm:w-80 ${ theme.bigMenuIsOpen ? "" : "hidden" }`}>
              <Tab.Panel className={"h-full"}>
                <AddObjectComp />
              </Tab.Panel>
              <Tab.Panel>Content 2</Tab.Panel>
              <Tab.Panel>Content 3</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};
export default connect(mapStateToProps , mapDispatchToProps)(Sidebar);
