import axios from "axios";
import * as THREE from "three";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import Editor from "./components/editor";

import LoadObject, { addObj } from "./utils/editor/tools/objects/loader";

import { Provider } from "react-redux";
import { useStore } from "./utils/redux/store";

function App({editor}) {
  const store = useStore({
    editor: editor,
  });
  return (
    <>
    
      <Provider store={store}><Editor /></Provider>
      
    </>
  );
}

export default App
