import { useEffect } from "react";

export default function ObjectEditor({ editor, setEditor, ...props }) {
  const objectChangeEvent = (e) => {
    setEditor({
      ...editor,
      controls: { ...editor.controls, transformControls: e.target },
    });
  };
  useEffect(() => {
    editor.controls.transformControls.addEventListener(
      "objectChange",
      objectChangeEvent
    );

    // cleanup this component
    return () => {
      editor.controls.transformControls.removeEventListener(
        "objectChange",
        objectChangeEvent
      );
    };
  }, []);

  if (editor.controls.transformControls.object === undefined) {
    return <></>;
  }

  return (
    <>
      <div className="h-full flex flex-col ">
        <div className="p-2">
          <h3 className="text-md text-white"> Settings</h3>
        </div>
        <hr />
        <div className="grow bg-gray-900 rounded-b overflow-y-auto">
          <div className="border text-white p-2">
            <h4 className="">Position</h4>
            <div className="flex position-x p-2">
              <span className="w-8"> X </span>{" "}
              <input
                className="grow bg-gray-900"
                onChange={(e) => {
                  editor.controls.transformControls.object.position.set(
                    e.target.value,
                    editor.controls.transformControls.object.position.y,
                    editor.controls.transformControls.object.position.z
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.position.x}
              />
            </div>
            <div className="flex position-y p-2">
              <span className="w-8"> Y </span>{" "}
              <input
                className="grow bg-gray-900"
                onChange={(e) => {
                  editor.controls.transformControls.object.position.set(
                    editor.controls.transformControls.object.position.x,
                    e.target.value,
                    editor.controls.transformControls.object.position.z
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.position.y}
              />
            </div>
            <div className="flex position-z p-2">
              <span className="w-8"> Z </span>{" "}
              <input
                className="grow bg-gray-900 overflow-y-auto"
                onChange={(e) => {
                  editor.controls.transformControls.object.position.set(
                    editor.controls.transformControls.object.position.x,
                    editor.controls.transformControls.object.position.y,
                    e.target.value
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.position.z}
              />
            </div>
          </div>
          <div className="border text-white p-2">
            <h4 className="">Scale</h4>
            <div className="flex scale-x p-2">
              <span className="w-8"> X </span>{" "}
              <input
                className="grow bg-gray-900"
                onChange={(e) => {
                  editor.controls.transformControls.object.scale.set(
                    e.target.value,
                    editor.controls.transformControls.object.scale.y,
                    editor.controls.transformControls.object.scale.z
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.scale.x}
              />
            </div>
            <div className="flex scale-y p-2">
              <span className="w-8"> Y </span>{" "}
              <input
                className="grow bg-gray-900"
                onChange={(e) => {
                  editor.controls.transformControls.object.scale.set(
                    editor.controls.transformControls.object.scale.x,
                    e.target.value,
                    editor.controls.transformControls.object.scale.z
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.scale.y}
              />
            </div>
            <div className="flex scale-z p-2">
              <span className="w-8"> Z </span>{" "}
              <input
                className="grow bg-gray-900"
                onChange={(e) => {
                  editor.controls.transformControls.object.scale.set(
                    editor.controls.transformControls.object.scale.x,
                    editor.controls.transformControls.object.scale.y,
                    e.target.value
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.scale.z}
              />
            </div>
          </div>
          <div className="border text-white p-2">
            <h4 className="">Rotation</h4>
            <div className="flex rotation-x p-2">
              <span className="w-8"> X </span>{" "}
              <input
                className="grow bg-gray-900"
                onChange={(e) => {
                  editor.controls.transformControls.object.rotation.set(
                    e.target.value,
                    editor.controls.transformControls.object.rotation.y,
                    editor.controls.transformControls.object.rotation.z
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.rotation.x}
              />
            </div>
            <div className="flex rotation-y p-2">
              <span className="w-8"> Y </span>{" "}
              <input
                className="grow bg-gray-900"
                onChange={(e) => {
                  editor.controls.transformControls.object.rotation.set(
                    editor.controls.transformControls.object.rotation.x,
                    e.target.value,
                    editor.controls.transformControls.object.rotation.z
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.rotation.y}
              />
            </div>
            <div className="flex rotation-z p-2">
              <span className="w-8"> Z </span>{" "}
              <input
                className="grow bg-gray-900"
                onChange={(e) => {
                  editor.controls.transformControls.object.rotation.set(
                    editor.controls.transformControls.object.rotation.x,
                    editor.controls.transformControls.object.rotation.y,
                    e.target.value
                  );
                  setEditor({
                    ...editor,
                    controls: {
                      ...editor.controls,
                      transformControls: editor.controls.transformControls,
                    },
                  });
                }}
                type={"number"}
                value={editor.controls.transformControls.object.rotation.z}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
