import axios from "axios"

const Api = axios.create({
    baseURL:"https://v1.webarpro.com/api/"
})

const refreshToken = async () => {
    return await Api.get("/reauth")
  }
  
  Api.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const config = error.config
      console.log("error")
      console.table(error)
      if (error.response && error.response.status === 401) {
        const newRes = await refreshToken()
          .then(() => {
            console.log("jk")
            return Api(config)
          })
          .catch(async () => {
            throw error
          })
        return newRes
      }
      throw error
    }
  )

  
export default Api